import React from 'react';
import styled from 'styled-components';

import Layout from '../components/layout';
import Head from '../components/head';

const P = styled.p`
  line-height: 1.4;
  margin-bottom: 8px;
`;

const Title = styled.h1`
  margin: 20px 0;
`;

export default function AboutPage() {
  return (
    <Layout>
      <Head title="About" />
      <Title>Hi there! 👋</Title>
      <P>
        I am Akibur Rahman. Everybody calls me Akib. I am a full-stack
        developer. I love to share what I have learned and experienced.
      </P>
      <P>
        I learned about programming from my elder brother (
        <a href="https://medium.com/@jonnlinn" target="__blank">
          Uttam Kumar Roy
        </a>
        , The biggest inspiration in my life) in 2014 or 2015. One day I went to
        his house to watch a movie on his computer. I saw he is doing something.
        I sat on a chair. Then he gave me the keyboard and told me "Enter a
        number, It will tell you that number Odd or Even."
      </P>
      <P>
        After typing a couple of numbers, I was amazed. Then I told him "how did
        you do that?" Then he told me, "It is done by programming and in
        computer, everything happening by programming like playing games,
        listening songs or watching movie." And first I know about programming.
      </P>
      <P>
        Then bought a blank DVD drive and I took every resource from him, he
        had. Because back then internet is not available. From then in my head
        added the thing very deeply, I have to know programming at any cost. But
        that time I had no computer or I don't even know how to operate the
        computer properly. But a desire was created.
      </P>
      <P>
        In mid-2016, I got my first android phone. From the desire, I started
        learning C on my phone using{' '}
        <a href="http://cpbook.subeen.com/" target="__blank">
          this book
        </a>
        . I downloaded the C compiler on my phone and run the code in that app.
        My phone was very slow I can't run every code. For that my learning
        progress getting slow. For that reason, I went to the college library
        and used a computer to run those codes which I can't run properly on my
        phone. By doing this I learn the basics of programming(variable, data
        types, loop, conditions, functions).
      </P>
      <P>
        In May 2017, I got my first computer. That time I was pretty excited, I
        am going to learn to program. This was the reason I bought the computer.
        And then I start learning C from the beginning even though I know the
        basics. And most of my time spending in front of the computer. After
        getting comfort in basic concepts, I start solving problems in OJ (
        <a href="https://dimikoj.com/" target="__blank">
          DimikOJ
        </a>
        ,{' '}
        <a href="https://lightoj.com/" target="__blank">
          LightOJ
        </a>
        ). At this time, I was very confused about should learn C or pick other
        languages. I got sad by thinking that I am picking the wrong language.
      </P>
      <P>
        In July 2018, I first know to Web Designing/Web Developing. From then I
        started learning HTML, CSS, JS. After learning JS, I started learning
        NodeJS. Then start learning ExpressJs and MongoDB. After getting into
        web development, I learned many frameworks for my work like ReactJS,
        GatsbyJS, NextJS, MongooseJS, VueJS.
      </P>
      <P>In Oct 2019, I got first internship.</P>
      <P>In Feb 2020, I got first job.</P>
      <P>
        This is how my journey started and continues on! There is a lot more to
        travel.
      </P>
      <P>
        Oh! I forgot to tell you. I love to read books as well as addicted to
        music, especially Bengali Band songs.
      </P>
    </Layout>
  );
}
